<template>
  <div id="sidebar-component">
    <div class="sidebar-wrapper">

      <div class="mt-4 text-center">
        <router-link v-if="this.$store.getters.userIsAdmin" class="w-75 navbar-brand" :to="{ name: 'dashboard' }">
          <img class="w-100" :src="require('../../../public/images/logo.png')" alt="Logo">
        </router-link>
        <router-link v-else-if="this.$store.getters.userIsMarketing" class="w-75 navbar-brand"
                     :to="{ name: 'export-marketing-index' }">
          <img class="w-100" :src="require('../../../public/images/logo.png')" alt="Logo">
        </router-link>
      </div>

      <!-- Sidebar -->
      <nav class="" v-if="this.$store.getters.userIsAdmin || this.$store.getters.userIsMarketing" id="sidebar-content">
        <ul class="nav flex-column">
          {{ /* home */ }}
          <li v-if="this.$store.getters.userIsAdmin" class="label-sidebar text-grey mb-2 ml-3">Accueil</li>
          <router-link class="nav-link p-0" :to="{ name: 'dashboard' }">
            <li v-if="this.$store.getters.userIsAdmin" class="nav-item p-2"
                :class="(this.$route.name.includes('dashboard')) ? 'sidebar-active' : ''" v-on:click="clickEventLink">
              <div class="d-flex align-items-center ml-3">
                <b-icon class="mr-4" icon="bar-chart-line" font-scale="1.2" variant="white"></b-icon>
                Tableau de bord
              </div>
            </li>
          </router-link>
          <li v-if="this.$store.getters.userIsAdmin">
            <hr class="separator">
          </li>
          {{ /* Flux */ }}
          <li v-if="this.$store.getters.userIsAdmin" class="label-sidebar text-grey mb-2 ml-3">Centre</li>
          <router-link class="nav-link p-0" :to="{ name: 'center-flux' }">
            <li v-if="this.$store.getters.userIsAdmin" class="nav-item p-2"
                :class="(this.$route.name === 'center-flux') ? 'sidebar-active' : ''" v-on:click="clickEventLink">
              <div class="d-flex ml-3 align-items-center">
                <b-icon class="mr-4" icon="graph-up" font-scale="1.2" variant="white"></b-icon>
                Flux visiteurs
              </div>
            </li>
          </router-link>
          {{ /* Cncc */ }}
          <router-link class="nav-link p-0" :to="{ name: 'center-cncc' }">
            <li v-if="this.$store.getters.userIsAdmin" class="nav-item p-2"
                :class="(this.$route.name === 'center-cncc') ? 'sidebar-active' : ''" v-on:click="clickEventLink">
              <div class="d-flex ml-3 align-items">
                <b-icon class="mr-4" icon="bar-chart" font-scale="1.2" variant="white"></b-icon>
                Cncc
              </div>
            </li>
          </router-link>
          {{ /* Key Figures */ }}
          <router-link class="nav-link p-0" :to="{ name: 'center-key-figures' }">
            <li v-if="this.$store.getters.userIsAdmin" class="nav-item p-2"
                :class="(this.$route.name === 'center-key-figures') ? 'sidebar-active' : ''"
                v-on:click="clickEventLink">
              <div class="d-flex align-items-center ml-3">
                <b-icon class="mr-4" icon="key" font-scale="1" variant="white"></b-icon>
                Chiffres clés
              </div>
            </li>
          </router-link>
          <li v-if="this.$store.getters.userIsAdmin">
            <hr class="separator">
          </li>
          {{ /* Management */ }}
          <li v-if="this.$store.getters.userIsAdmin" class="label-sidebar text-grey mb-2 ml-3">Gestion</li>
          <router-link class="nav-link p-0" :to="{ name: 'shop-list' }">
            <li v-if="this.$store.getters.userIsAdmin" class="nav-item p-2"
                :class="(this.$route.name.includes('shop') || this.$route.name.includes('sale')) ? 'sidebar-active' : ''"
                v-on:click="clickEventLink">
              <div class="d-flex ml-3 align-items-center">
                <b-icon class="mr-4" icon="shop" font-scale="1" variant="white"></b-icon>
                Commerces
              </div>
            </li>
          </router-link>
          <router-link class="nav-link p-0" :to="{ name: 'user-list' }">
            <li v-if="this.$store.getters.userIsAdmin" class="nav-item p-2"
                :class="(this.$route.name.includes('user')) ? 'sidebar-active' : ''" v-on:click="clickEventLink">
              <div class="d-flex ml-3 align-items-center">
                <b-icon class="mr-4" icon="person" font-scale="1" variant="white"></b-icon>
                Utilisateurs
              </div>
            </li>
          </router-link>
          <router-link class="nav-link p-0" :to="{ name: 'category-list' }">
            <li v-if="this.$store.getters.userIsAdmin" class="nav-item p-2"
                :class="(this.$route.name.includes('category')) ? 'sidebar-active' : ''" v-on:click="clickEventLink">
              <div class="d-flex ml-3 align-items-center">
                <b-icon class="mr-4" icon="tag" font-scale="1" variant="white"></b-icon>
                Catégories
              </div>
            </li>
          </router-link>
          <router-link class="nav-link p-0" :to="{ name: 'company-list' }">
            <li v-if="this.$store.getters.userIsAdmin" class="nav-item p-2"
                :class="(this.$route.name.includes('company')) ? 'sidebar-active' : ''" v-on:click="clickEventLink">
              <div class="d-flex ml-3 align-items-center">
                <b-icon class="mr-4" icon="building" font-scale="1" variant="white"></b-icon>
                Entreprises
              </div>
            </li>
          </router-link>
          <router-link class="nav-link p-0" :to="{ name: 'calendar-index' }">
            <li  class="nav-item p-2" :class="(this.$route.name.includes('calendar')) ? 'sidebar-active' : ''"
                 v-on:click="clickEventLink">
              <div class="d-flex ml-3 align-items-center">
                <b-icon class="mr-4" icon="calendar3" font-scale="1" variant="white"></b-icon>
                Calendrier
              </div>
            </li>
          </router-link>
          <li v-if="this.$store.getters.userIsAdmin">
            <hr class="separator">
          </li>
          {{ /* Export */ }}
          <li v-if="this.$store.getters.userIsAdmin" class="label-sidebar text-grey mb-2 ml-3">Export</li>
          <router-link class="nav-link p-0" :to="{ name: 'export-index' }">
            <li class="nav-item p-2" :class="(this.$route.name === 'export-index') ? 'sidebar-active' : ''"
                v-on:click="clickEventLink">
              <div class="d-flex ml-3 align-items-center">
                <b-icon class="mr-4" icon="file-earmark-arrow-down" font-scale="1" variant="white"></b-icon>
                Bilan mensuel
              </div>
            </li>
          </router-link>
          <router-link class="nav-link p-0" :to="{ name: 'export-stats-index' }">
            <li class="nav-item p-2" :class="(this.$route.name === 'export-stats-index') ? 'sidebar-active' : ''"
                v-on:click="clickEventLink">
              <div class="d-flex ml-3 align-items-center">
                <b-icon class="mr-4" icon="file-earmark-arrow-down" font-scale="1" variant="white"></b-icon>
                Comparaison CC vs CNCC
              </div>
            </li>
          </router-link>
          <router-link class="nav-link p-0" :to="{ name: 'export-comparative-index' }">
            <li class="nav-item p-2" :class="(this.$route.name === 'export-comparative-index') ? 'sidebar-active' : ''"
                v-on:click="clickEventLink">
              <div class="d-flex ml-3 align-items-center">
                <b-icon class="mr-4" icon="file-earmark-arrow-down" font-scale="1" variant="white"></b-icon>
                Comparatif boutiques
                similaires
              </div>
            </li>
          </router-link>
          <router-link class="nav-link p-0" :to="{ name: 'export-marketing-index' }">
            <li class="nav-item p-2" :class="(this.$route.name === 'export-marketing-index') ? 'sidebar-active' : ''"
                v-on:click="clickEventLink">
              <div class="d-flex ml-3 align-items-center">
                <b-icon class="mr-4" icon="file-earmark-arrow-down" font-scale="1" variant="white"></b-icon>
                Evolution CA par période
              </div>
            </li>
          </router-link>
          <router-link class="nav-link p-0" :to="{ name: 'export-ca-index' }">
            <li class="nav-item p-2" :class="(this.$route.name === 'export-ca-index') ? 'sidebar-active' : ''"
                v-on:click="clickEventLink">
              <div class="d-flex ml-3 align-items-center">
                <b-icon class="mr-4" icon="file-earmark-arrow-down" font-scale="1" variant="white"></b-icon>
                Export CA
              </div>
            </li>
          </router-link>
          <li v-if="this.$store.getters.userIsAdmin">
            <hr class="separator">
          </li>
          {{ /* Podium */ }}
          <li v-if="this.$store.getters.userIsAdmin" class="label-sidebar text-grey mb-2 ml-3">Classement</li>
          <router-link class="nav-link p-0" :to="{ name: 'podium-index' }">
            <li class="nav-item p-2" :class="(this.$route.name === 'podium-index') ? 'sidebar-active' : ''"
                v-on:click="clickEventLink">
              <div class="d-flex ml-3 align-items-center">
                <b-icon class="mr-4" icon="trophy" font-scale="1" variant="white"></b-icon>
                Podiums
              </div>
            </li>
          </router-link>
          <router-link class="nav-link p-0" :to="{ name: 'histogram-index' }">
            <li class="nav-item p-2" :class="(this.$route.name === 'histogram-index') ? 'sidebar-active' : ''"
                v-on:click="clickEventLink">
              <div class="d-flex ml-3 align-items-center">
                <b-icon class="mr-4" icon="bar-chart" font-scale="1" variant="white"></b-icon>
                Histogram
              </div>
            </li>
          </router-link>
        </ul>
      </nav>

      <div class="footer-sidebar small-text text-grey text-center p-4">
        Socri REIM
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  props: {
    isMobile: {
      type: Boolean
    },
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    }
  },
  methods: {
    clickEventLink() {
      if (this.isMobile) {
        this.showSidebar = false

        this.$emit('click-event-link', {showSidebar: this.showSidebar, isMobile: this.isMobile})
      }
    },
    handleCollapsedSidebar(windowWidth) {
      this.showSidebar = windowWidth >= 1069

      this.$emit('resize-collapsed', {showSidebar: this.showSidebar})
    }
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  },
  created() {
    this.windowWidth = window.innerWidth

    this.handleCollapsedSidebar(this.windowWidth)
  },
  watch: {
    windowWidth: function (windowWith) {
      this.handleCollapsedSidebar(windowWith)
    }
  },
  computed: {
    ...mapState(['ChannelModule']),
  }
}
</script>
